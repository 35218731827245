import Head from "next/head";
import React from "react";
import LandingPageComponent from "../components/LandingPage/LandingPage";
import { UserGuard } from "../Guard/UserGuard";
const LandingPage: React.FC = (): JSX.Element => (
  <UserGuard>
    <Head>
      <title>Landing Page</title>
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" content="IntuCharge" />
    </Head>
    <LandingPageComponent />
  </UserGuard>
);

export default LandingPage;

import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import LandingPageLayout from "../../layout/LandingPageLayout";
import Button from "../CommonSection/Buttons/Button";
import classes from "./LandingPage.module.scss";

const LandingPage: React.FC = (): JSX.Element => {
  const router = useRouter();
  return (
    <LandingPageLayout>
      <div className={`${classes.landing_section}`}>
        <div className={`${classes.landing_box}`}>
          <div className="w-full grid">
            <Image
              src="/images/fleetcharging.png"
              alt="Image"
              layout="responsive"
              width={994}
              height={619}
              objectFit="contain"
            />
          </div>
          <div className={`${classes.landing_card}`}>
            <div className={`${classes.landing_title}`}>
              <div>Experience the</div>

              <div>
                <span>future of</span>
                <span className="text-primary-main">&nbsp; EV charging</span>
              </div>

              <div>with IntuCharge</div>
            </div>

            <p className={`${classes.landing_description}`}>
              It is a long established fact that a reader will be distracted by the
              <span className="lg:block">
                readable content of a page when looking at its layout.
              </span>
            </p>

            <div>
              <Button
                variant="primary"
                size="large"
                onClick={() => {
                  router.push("/signup");
                }}
                title="Let's Register"
              >
                Let&apos;s Register
              </Button>
            </div>

            <div className={`${classes.landing_login_text}`}>
              <span>Already Registered? &nbsp;</span>
              <span className="text-primary-main" title="Login">
                <Link href="/login">Login</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </LandingPageLayout>
  );
};

export default LandingPage;
